import { ReloadEventData } from 'features/analytics/redux/analytics-v2.actions';
import { Subject } from 'rxjs/Subject';
import { Offer } from 'shared/models/offers/offer.model';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';

export const OFFER_MODAL = 'find-loads-offer';

export interface OfferModalContext {
  visibilityChanges: Subject<boolean>;
  isEditing?: boolean;
  load: AvailableLoadSummary;
  offer?: Offer;
  reloadData?: ReloadEventData;
  makeNewOffer?: boolean;
  isFromOffers?: boolean;
  isCounterOffer?: boolean;
  position?: number;
}
